<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
  >
    <br>
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeCreate',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ]
}
</script>
